<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="$emit('backToMenu')">Back Without Saving </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading ...</p>
        </div>

        <div class="box" v-if="loading==false">	
              
            
            <form @submit.prevent="saveNmcDetails">  
                <h4 class="title is-4">Access NI</h4>
                    <p>You must request an Access NI check before working for Balmoral Healthcare. Please click the link below to start the process and then press the button to let us know you have performed that step.</p>
                    <p><a href="https://www.nidirect.gov.uk/services/apply-online-basic-check" target="_blank">https://www.nidirect.gov.uk/services/apply-online-basic-check</a></p>
                    <p>If you believe you already have an Access NI check which is suitable, please press the button below and we will look that up in the system.</p>
                    
                    <div v-if="access_ni_details.start_date != '' && access_ni_details.start_date != null"><strong>You have already let us know that your Access NI Check has been applied for on {{  access_ni_details.applied_for_date }}</strong></div>
                    <div v-else>
                        <div class="field is-grouped">
                            <div class="control">
                                <button class="button is-success" :disabled="working===true" @click="saveAccessNi()">I Already Have A Check or Have Started The Process Above</button>
                            </div>
                        </div>
                    </div>
                </form>
            
          </div>
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'ViewApplication',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:false,
                   accessToken: '',
                   access_ni_details: []
            }
        },
        created() {
          this.getAccessNiDetails()
          
        },
        methods: {
            
            async getAccessNiDetails() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/access_ni').then(
                    response => {
                        this.access_ni_details = response 
                        this.loading = false;
                    }
                );
            },
            async saveAccessNi() {
                
                this.working = true
                var formdata = { 
                    applied_for : 1,
                }; 

                
                MemberApplicationService.saveAccessNi(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    this.$emit('backToMenu')
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        }
                        if(error.response.status === 406)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The barcodes passed in were not valid. Please double check and try again.")
                        }
                        
                        this.working = false
                });
                

                
            },
            
           
        }
    }
    </script>