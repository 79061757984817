<template>
    <div class="home">
          <div class="box">	
              <form @submit.prevent="submitApplication" v-if="submitted===false">
                      <div class="field">
                          <label class="label">Forename</label>
                          <div class="control">
                              <input class="input" type="text" v-model="forename" autofocus required>
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Surname</label>
                          <div class="control">
                              <input class="input" type="text" v-model="surname" required>
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Email Address</label>
                          <div class="control">
                              <input class="input" type="email" v-model="email" required >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Date of Birth</label>
                          <div class="control">
                              <input class="input" type="date" v-model="date_of_birth" required >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Grade Applied For</label>
                          <div class="select">
                            <select  v-model="grade_id">
                                <option>Select grade</option>
                                <option v-for="row in grades" :key="row.id" :value="row.id">{{  row.name  }}</option>
                            </select>
                            </div>
                            <br />
                            <small>Once approved, we  can offer you shifts at varous grade. Please enter your main grade here.</small>
                      </div>

                      <div class="field">
                          <label class="label">NMC Pin Number</label>
                          <div class="control">
                              <input class="input" type="text" v-model="nmc_pin_number"  >
                          </div>
                      </div>

                      
                     
                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Submit Application</button>
                          </div>
                      </div>
                  </form>

                  <div class="notification is-success content" v-if="submitted==true">
                   
                    <p>Thank you for starting your application with Balmoral Healthcare. We will review your application and respond by email as soon as possible.</p>
                    <p>We will require some further documentation and also some references. These will all be emailed to you to complete.</p>
                    </div>
            
          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'NewApplication',
        mixins: [],
        data: function () {
            return {
                working : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    forename: '',
                    surname: '',
                    email: '',
                    date_of_birth: '',
                    nmc_pin_number: '',
                    grade_id: '',
                    submitted: false,
                    grades: []
                    
                   
            }
        },
        created() {
          
              this.getGrades();
           
        },
        methods: {
            
           
            async submitApplication() {
                this.working = true;

                var formdata = { 
                    forename : this.forename,
                    surname: this.surname,
                    email: this.email,
                    date_of_birth: this.date_of_birth,
                    nmc_pin_number : this.nmc_pin_number,
                    grade_id : this.grade_id
                    
                }; 

                MemberApplicationService.saveApplication(this.accessToken, formdata)
                .then((response) => {
                    this.submitted = true
                    this.working = false;
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false;
                });
            },
            async getGrades() {
                UtilityService.getSimpleApiData(this.accessToken, "web/grades").then(
                    response => this.grades = response 
                );
            },
           
        }
    }
    </script>