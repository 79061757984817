import axios from "axios"

let API_NAME = process.env.VUE_APP_API_NAME;

export default {
	async getStones(accessToken) {
		let res = await axios.get(API_NAME + "/stones", {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  async saveApplication(accessToken,  formdata) {
    let res = await axios.post(API_NAME + "/web/applications",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
async saveBankDetails(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/bank_details',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveNmcDetails(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/nmc_details',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveAccessNi(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/access_ni',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  
  
}