<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="$emit('backToMenu')">Back Without Saving </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading ...</p>
        </div>

        <div class="box" v-if="loading==false">	
              
            
            <form @submit.prevent="saveNmcDetails">  
                    <div class="field">
                        <label class="label">PIN Number</label>
                        <div class="control">
                            <input type="text" class="input" v-model="nmc_details.pin_number" autofocus maxlength="20">
                        </div>
                    </div>
                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Save</button>
                          </div>
                      </div>
                  </form>
            
          </div>
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'ViewApplication',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:false,
                   accessToken: '',
                   nmc_details: []
            }
        },
        created() {
          this.getApplicationNmcDetails()
          
        },
        methods: {
            
            async getApplicationNmcDetails() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/nmc_details').then(
                    response => {
                        this.nmc_details = response 
                        this.loading = false;
                    }
                );
            },
            async saveNmcDetails() {
                this.working = true
                var formdata = { 
                    pin_number : this.nmc_details.pin_number,
                }; 

                
                MemberApplicationService.saveNmcDetails(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    this.$emit('backToMenu')
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        }
                        if(error.response.status === 406)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The barcodes passed in were not valid. Please double check and try again.")
                        }
                        
                        this.working = false
                });
                

                
            },
            
           
        }
    }
    </script>