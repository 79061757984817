<template>
    <div class="home">
          <div class="box">	
            <h1 class="title">Save Reference</h1>
              <form @submit.prevent="submitApplication" v-if="loading===false">
                      <div class="field">
                          <label class="label">Forename</label>
                          <div class="control">
                              <input class="input" type="text"  autofocus required>
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Surname</label>
                          <div class="control">
                              <input class="input" type="text"  >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Email Address</label>
                          <div class="control">
                              <input class="input" type="email"  >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Date of Birth</label>
                          <div class="control">
                              <input class="input" type="date"  >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">How Do You Know The Applicant</label>
                          <div class="control">
                              <textarea class="textarea"  ></textarea>
                          </div>
                      </div>
                     
                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="loading===true">Submit Reference</button>
                          </div>
                      </div>
                  </form>

                  <div class="notification is-success content" v-if="loading==true">
                   
                    <p>Thank you for submitting your details. We will get in touch by email if we require any further information.</p>
                    </div>
            
          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    //import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddOrganisation',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    name: '',
                    
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
              
            });
          
        },
        methods: {
            
            async submitApplication() {
			
                this.loading = true
            },
           
        }
    }
    </script>