<template>
    <div>
        <div class="notification is-warning " v-if="loading==true">
            <p>Loading ...</p>
        </div>

        <div v-if="loading==false" class=" content">
            <div class="notification is-success " v-if="this.application.completed==1">
                <p>This application has been completed and can no longer be updated using this website.</p>
            </div>
            <div class="notification is-danger " v-if="this.application.completed==0 && application.archived==1">
                <p>This application has been archived and can no longer be updated using this website.</p>
            </div>
            
            <div v-if="this.application.completed==0 && application.archived==0">
                <div class="notification is-info " v-if="this.application.status==1 || this.application.status==2">
                    <p>Your application is being processed by Balmoral Healthcare. We will respond by email to let you know what the next step is.</p>
                    <p>Please send us an email if you have any queries regarding your application.</p>
                </div>
                <div v-else>
                    
                    <div v-if="componentToView == ''">
                        <p>You are required to complete the following sections. Please press each button, fill in the details and then save each section before pressing Return to Balmoral.</p>
                        
                        <div class="columns buttons are-large">
                            <div class="column is-full " v-for="row in this.application.applicant_stages" :key="row.id">
                                <button class="button is-info is-fullwidth" @click="componentToView=row.component">{{ row.stage_name}}</button>
                            </div> 
                            
                            <div class="column is-full">
                                <button class="button is-success is-fullwidth" @click="returnApplication()">Return to Balmoral</button>
                                <small>Please make sure you have completed all sections as the application will be locked once you return it to us. It will be re-opened if we require more information from you.</small>
                            </div>
                        </div> 
                    </div>


                    <PersonalDetails v-if="componentToView=='personal'" application="application" @backToMenu="showMenu"/>

                    <HealthAssessment v-if="componentToView=='health_assessment'" application="application" @backToMenu="showMenu"/>
                    
                    <BankDetails v-if="componentToView=='bank_details'" application="application" @backToMenu="showMenu"/>

                    <NmcDetails v-if="componentToView=='nmc'" application="application" @backToMenu="showMenu"/>

                    <AccessNi v-if="componentToView=='access_ni'" application="application" @backToMenu="showMenu"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import UtilityService from '@/services/UtilityService.js';

    import BankDetails from '@/components/BankDetails.vue'
    import NmcDetails from '@/components/NmcDetails.vue'
    import AccessNi from '@/components/AccessNi.vue'
    import HealthAssessment from '@/components/HealthAssessment.vue'
    import PersonalDetails from '@/components/PersonalDetails.vue'

    export default {
      name: 'ViewApplication',
        mixins: [],
        components: {
            BankDetails,
            NmcDetails,
            AccessNi,
            PersonalDetails,
            HealthAssessment
        },
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:false,
                   application: [],
                   accessToken: '',
                   componentToView: ''
            }
        },
        created() {
          //this.getAccessToken()
          //  .then(() => { 
          //      console.log(this.application_code)
                this.getApplication()
          //  });
          
        },
        methods: {
            showMenu() {
                this.componentToView = ''
                this.getApplication()
            },
            async returnApplication() {
                if(confirm('Please make sure you have completed and saved all of the sections before returning your application ?'))
                {
                    this.loading = true;
                    UtilityService.postSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/return').then(
                        response => {
                            this.application = response 
                            this.loading = false;
                        }
                    );
                }
            },
            async getApplication() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code).then(
                    response => {
                        this.application = response 
                        this.loading = false;
                    }
                );
            },
            
           
        }
    }
    </script>